<template>
  <div :style="slidePosition" class="w-100 h-100">
    <div
      iv
      class="position-absolute text-white "
      style="width:52.5%;left:7.5%;top:29%;transform: translateY(-50%)"
    >
      <h3 class="font-vw-3-5 font-w-600 mb-0 fade21" style="opacity:0;">
        2010
      </h3>
      <h6 class="font-vw-2-5 font-w-600 fade21 " style="opacity:0; ">
        ELECTRONS FOR THE FUTURE
      </h6>
    </div>
    <div
      iv
      class="position-absolute text-white "
      style="width:52.5%;left:7.5%;top:42%;transform: translateY(-0%)"
    >
      <p class="font-vw-1-35 text-21" style="opacity:0; ">
        To increase rare isotope beam production for experiments, TRIUMF began
        construction on a new superconducting electron linear accelerator
        (e-linac) in 2010, achieving its first beam four years later in 2014.
        The 25-metre-long e-linac accelerates a high-intensity beam of electrons
        to 99.99% of the speed of light and steers them towards a rare isotope
        production target. The TRIUMF-designed and constructed e-linac is the
        world’s highest power e-linac for rare isotope production and will come
        fully online in 2023. It is the centrepiece of the Advanced Rare Isotope
        Laboratory (ARIEL), which promises to revolutionize the study of
        isotopes for science, medicine, and business, and to provide training to
        a new generation of scientists.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { normalize } from "@/utils/ranges"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  mounted() {
    //this.startLoop()
  },
  methods: {
    slide21(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 500
      })

      animation
        .add({
          targets: ".fade21",

          translateY: ["-50%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine",
          delay: (el, i) => 5000 + 30 * i
        })
        .add({
          targets: ".text-21",

          opacity: [0, 1],

          translateY: ["35%", "0%"],
          duration: 100,
          easing: "easeInOutSine"
        })
      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
    },
    startLoop() {
      anime({
        targets: ".particle",
        width: ["32%", "35%"],
        direction: "alternate",
        loop: true,
        duration: 1200,
        easing: "easeInOutSine"
      })
    }
  },
  watch: {
    innerPosition() {
      this.slide21(this.innerPosition)
    }
  }
}
</script>

<style></style>
